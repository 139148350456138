import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {get, post} from "./index";
import Loading from "../components/Loading";

const authContext = createContext();

const useAuth = () => {
    return useContext(authContext);
}

const useProvideAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(false);
    const onLoadedPromises = useRef([]);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            await loadUser();
            setLoading(false);
        })();
    }, []);

    const onLoaded = async () => {
        try {
            return await new Promise((resolve, reject) => {
                if (!loading) return resolve(user);
                onLoadedPromises.current.push({resolve, reject});
            });
        } catch (e) {
            return null;
        }
    }

    async function login(email, password, err) {
        try {
            let {data: _user, redirect: red} = await post(`/auth/landlord/login`, {email, password});
            setUser(_user);
        } catch (e) {
            throw e;
        }
    }

    async function signup(user) {
        let _user = await post(`/auth/landlord/signup`, {...user});
        if(_user) setUser(_user);
        return _user;
    }

    async function logout() {
        try {
            let logoutRes = await get(`/auth/landlord/logout`);
            if (logoutRes.redirect) {
                return window.location.href = logoutRes.redirect;
            }
            return logoutRes;
        } catch (e) {
            throw e;
        }
    }

    const loadUser = async () => {
        console.log("loading user");
        if(user) return user;
        try {
            let _user = await getUser();
            onLoadedPromises.current.forEach(p=>p.resolve(_user));
            return _user;
        } catch (err) {
            onLoadedPromises.current.forEach(p=>p.reject(err));
            return null;
        }
    }

    async function getUser() {
        try {
            let _user = await get(`/auth/landlord/me`);
            setUser(_user);
            if ((!_user.email_verified || !_user.phone_verified || (!_user.has_password && !_user.googleId && !_user.facebookId)) && !_user.impersonateOriginal && !window.location.href.includes("/start/verification")) {
                return window.location.href = "/start/verification";
            }
            return _user;
        } catch (e) {
            throw e;
        }
    }

    async function getApiKeys() {
        try {
            return await get(`/auth/landlord/getApiKeys`);
        } catch (e) {
            throw e;
        }
    }

    async function generateApiKey(name, readOnly) {
        try {
            return await post(`/auth/landlord/generateApiKey`, {name, readOnly});
        } catch (e) {
            throw e;
        }
    }

    async function deleteApiKey(id) {
        try {
            return await post(`/auth/landlord/deleteApiKey`, {id});
        } catch (e) {
            throw e;
        }
    }

    async function updateApiKey(id, readOnly) {
        try {
            return await post(`/auth/landlord/updateApiKey`, {id, readOnly});
        } catch (e) {
            throw e;
        }
    }

    return {
        user,
        setUser,
        loading,
        internalLoading,
        setInternalLoading,
        getUser,
        login,
        signup,
        logout,
        onLoaded,
        getApiKeys,
        generateApiKey,
        deleteApiKey,
        updateApiKey
    }
}

const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    if (process.env.NODE_ENV === "development") window._auth = auth;
    return (
        <authContext.Provider value={auth}>
            {auth.loading ? <Loading/> : children}
        </authContext.Provider>
    );
}

export {ProvideAuth, useAuth};

import React, {useEffect, useMemo, useState} from 'react';

import './style.less'
import {useHistory, useParams} from "react-router-dom";
import {reservationServices} from "../../../../../../services/reservationServices";
import Loading from "../../../../../../components/Loading";
import {useTranslation, Trans} from "react-i18next";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/es";
import { clampText, redactPhoneAndEmail } from "../../../../../../utils/stringFunctions";
import avatarFemale from "../../../../../../components/VisitCard/images/avatar_female.png";
import avatarMale from "../../../../../../components/VisitCard/images/avatar_male.png";
import {propertyServices} from "../../../../../../services";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../../components/MUIDialogComponents";
import {DialogContent} from "@material-ui/core";
import SuggestPropertyModal from "./modals/SuggestNewPropertyModal";
import SuggestNewDateModal from "./modals/SuggestNewDatesModal";
import RejectReservationModal from "./modals/RejectReservationModal";
import {BiInfoCircle} from "react-icons/bi";
import CancellationTimeline from "../../components/CancellationTimeline";
import NavBarGoBack from "../../../../../../components/NavBarGoBack";
import ActionDialog from "../../../../../../components/ActionDialog";
import FiltersContainer from "../../../../../../components/FiltersContainer";
import ReservationList from "../../components/ReservationsList";
import {useAuth} from "../../../../../../services/authServices";
import { getExpirationDate } from '../../../../../../utils/dateFunctions';
import NotInterestedIcon from "../../../../../../components/svg/NotInterested";
import CheckIcon from "../../../../../../components/svg/Check";
import ClearIcon from "../../../../../../components/svg/Clear";
import HourglassBottomIcon from "../../../../../../components/svg/HourglassBottom";
import EventAvailableIcon from "../../../../../../components/svg/EventAvailable";
import ArrowForwardIcon from "../../../../../../components/svg/Navigation/ArrowForward";
import ArrowBackwardIcon from "../../../../../../components/svg/Navigation/ArrowBackward";
import ArrowUpwardIcon from "../../../../../../components/svg/Navigation/ArrowUpward";
import DateRangeCalendarIcon from "../../../../../../components/svg/DateRangeCalendar";
import PersonAddIcon from "../../../../../../components/svg/PersonAdd";
import TrendingUpIcon from "../../../../../../components/svg/TrendingUp";
import AssignmentIcon from '../../../../../../components/svg/Assignment';
import AssignmentReturnIcon from '../../../../../../components/svg/AssignmentReturn';
import InfoCircleIcon from '../../../../../../components/svg/InfoCircle';
import CompareArrowsIcon from '../../../../../../components/svg/CompareArrows';
import CreditCardIcon from '../../../../../../components/svg/CreditCard';
import TimerIcon from '../../../../../../components/svg/Timer';
import PortraitIcon from '../../../../../../components/svg/Portrait';
import PaymentsIcon from '../../../../../../components/svg/Payments';
import ExpirationCountdown from '../../components/ExpirationCountdown';
import Tooltip from '../../../../../../components/Tooltip';
import RentsTimeline from "../../components/RentsTimeline";
import CancelIcon from "../../../../../../components/svg/Cancel";
import { isMobile } from '../../../../../../utils/responsive';
import Drawer from '../../../../../../components/Drawer';
import PROPERTY_BILLS from '../../../../../../utils/property-bills';
import ClampedText from '../../../../../../components/ClampedText';
import NoMeetingRoomIcon from "../../../../../../components/svg/NoMeetingRoom";
import ReasonMessageIcon from '../../../../../../components/svg/ReasonMessage';

const OutdatedAdInputs = ({
                              roomId,
                              room,
                              newRents,
                              setNewRents,
                              fixedRent,
                              setFixedRent,
                              rentMonths,
                              setRentMonths,
                              rent,
                              setRent,
                              newPeriodMin,
                              setNewPeriodMin,
                              periodMin,
                              setPeriodMin
                          }) => {

    const {t} = useTranslation();
    const [error, setError] = useState(false);
    const translationDirc = "main_page.reservations.reject_reservation."

    return (
        <div className={"details_outdated"}>
            <span>{t(translationDirc + "outdated_ad_info")}</span>
            <div className={"details-checkboxes"}>
                <div className={"details-input-container"} onChange={() => {
                    if (newRents) setFixedRent(room.fixedRent)
                    setNewRents(!newRents)
                }}>
                    <input type={"checkbox"} id={"rent"} name={"rent"} checked={newRents}/>
                    <label htmlFor={"rent"}>{t(translationDirc + "rent_label")}</label>
                </div>
                <div className={"details-input-container"}>
                    <input type={"checkbox"} id={"fixedRent"} name={"fixedRent"} disabled={!newRents} onChange={() => {
                        setFixedRent(!fixedRent)
                    }}
                           checked={fixedRent}/>
                    <label htmlFor={"fixedRent"}>{t(translationDirc + "fixedRent_label")}</label>
                </div>
            </div>
            {!fixedRent ?
                <div className={"rent-months-input"}>
                    {Object.keys(rentMonths).map(month => {
                        return (
                            <div className={"rent"} key={roomId + "rent-" + month}>
                                <label>{t("main_page.my_ads.create_ad.room_details.room_prices.rentMonths." + month)}</label>
                                <input type="number"
                                       placeholder={`€400`}
                                       className={"input_field"}
                                       key={"input-" + roomId + "rent-" + month}
                                       id={month + "-rent"}
                                       name={month}
                                       disabled={!newRents}
                                       value={rentMonths[month]}
                                       onClick={(e) => e.target.select()}
                                       onBlur={() => {
                                           if (new Set(Object.values(rentMonths)).size > 3) {
                                               setError(true)
                                           } else {
                                               setError(false);
                                           }
                                       }}
                                       onChange={(e) => {
                                           if (e.target.valueAsNumber > 9999 || e.target.valueAsNumber < 0) return;
                                           setRentMonths(prevState => ({
                                               ...prevState,
                                               [e.target.name]: e.target.valueAsNumber || 0
                                           }))
                                       }}/>
                            </div>
                        )
                    })}
                </div> :
                <div className={"rent-value-input"}>
                    <input className={"input_field"} type={"number"} id={"rent"} name={"rent"}
                           value={rent}
                           disabled={!newRents} onChange={(e) => {
                        if (e.target.valueAsNumber > 9999 || e.target.valueAsNumber < 0) return;
                        setRent(e.target.valueAsNumber)
                    }}/>
                    <span>{t(translationDirc + "currentPrice", {price: room.rent})}</span>
                </div>
            }
            {!fixedRent && <span className={"rents_info"} style={{color: (error ? "#C1381A" : "")}}><BiInfoCircle/>{t(translationDirc + "info_rentMonths")}</span>}
            <div className={"double-input"}>
                <div>
                    <input type={"checkbox"} id={"periodStay-check"} name={"newStayMin"}
                           checked={newPeriodMin}
                           onChange={() => setNewPeriodMin(!newPeriodMin)}/>
                    <label htmlFor={"periodStay-check"}>{t(translationDirc + "period_label")}</label>
                </div>
                <div>
                    <input className={"input_field"} type={"number"} id={"rent"} name={"periodStayMin"}
                           value={periodMin}
                           disabled={!newPeriodMin} onChange={(e) => setPeriodMin(e.target.valueAsNumber)}/>
                    <span>{t(translationDirc + "currentMinStay", {
                        count: room.periodStayMin === 1 ? 1 : 2,
                        period: room.periodStayMin
                    })}</span>
                </div>
            </div>
        </div>
    )
}


const PaymentSuccessModal = ({open, setOpen, t}) => {

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} className={"PaymentSuccessModal"} maxWidth={"xs"}>
            <DialogTitle className="DialogHeader" onClose={handleClose}/>
            <DialogContent className={"PaymentSuccessModal__content"}>
                <div className="payment-success">
                    <div className="check-icon">
                        <span className="icon-line line-tip"/>
                        <span className="icon-line line-long"/>
                        <div className="icon-circle"/>
                        <div className="icon-fix"/>
                    </div>
                </div>
                <span>{t("main_page.reservations.reservation_details.success_payment")}</span>
                <span>{t("main_page.reservations.reservation_details.reservation_cancelled")}</span>
            </DialogContent>
        </Dialog>
    )
}

const ReservationDetails = ({updateReservation}) => {

    const { t, i18n } = useTranslation();
    const {user, setUser} = useAuth();
    const history = useHistory();
    const {reservation_id} = useParams();
    const [reservation, setReservation] = useState(null);
    const [reservationStatus, setReservationStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [openSuggestDates, setOpenSuggestDates] = useState(false);
    const [openSuggestProperty, setOpenSuggestProperty] = useState(false);
    const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(null);
    const [drawerStates, setDrawerStates] = useState({});

    const translationDirc = "main_page.reservations.reservation_details."

    let params = (new URL(document.location)).searchParams;


    useEffect(() => {
        (async () => {
            try {
                let _reservation = await reservationServices.getReservationById(reservation_id);
                if(!_reservation.property.room) _reservation.property.room = _reservation.property && _reservation.property.rooms ? (_reservation.room_id ? Object.values(_reservation.property.rooms).find(r => r.id === _reservation.room_id) : _reservation.property.rooms["room_1"]) : null;
                setReservation(_reservation);
                if (params.get("cancellationPaymentSuccess") !== null) {
                    setOpenPaymentSuccessModal(true);
                    let listing_id = _reservation.property?.id;
                    if(_reservation.property?.accommodation !== "apartment") {
                        listing_id += "_" + (_reservation.property?.room?.id || _reservation.room_id);
                    }
                }
                if (params.get("rejectReservation") !== null && _reservation.currentStatus === "request") setOpenRejectModal(true);
                if (params.get("suggestNewDates") !== null && !_reservation.suggestionType) setOpenSuggestDates(true);
                if (params.get("suggestAnotherAd") !== null && !_reservation.suggestionType) setOpenSuggestProperty(true);
                if (params.get("showActionDialog") !== null && _reservation.suggestionType) setShowSuccessMessage(params.get("showActionDialog"));
            } catch (e) {
                console.log(e);
                history.push("/reservations/requests")
            }
        })();
    }, [reservation_id])


    const propertyImage = useMemo(() => {
        if (reservation?.property?.integration_version) {
            return [...(reservation.property.room_photos || []), ...(reservation.property.photos || [])][0]
        }
        return reservation?.room_id ? reservation?.property.room?.photos?.["featured"]?.md : reservation?.property?.photos?.["featured_0"][0]?.md
    }, [reservation]);
    let monthlyRent = useMemo(() => {
        if (!reservation) return;
        return reservationServices.getMonthlyRent(reservation);
    }, [reservation]);

    let roomNumber = reservation?.room_id && reservation?.property.rooms ? Object.keys(reservation.property.rooms)
        .find(rn => reservation.property.rooms[rn].id === reservation.room_id)
        .split("_")[1] : 1;

    let roomName = useMemo(() => {
        if (!reservation) return;
        return reservation?.room_id && reservation.property.rooms ? Object.values(reservation.property.rooms)
            .find(r => r.id === reservation.room_id).internalName : null;
    }, [reservation]);

    const getRoom = () => {
        let property = reservation.property;
        if (property?.room) return property.room;
        return property && property.rooms ? (reservation.room_id ? Object.values(property.rooms).find(r => r.id === reservation.room_id) : property.rooms["room_1"]) : null;
    }


    const getPaidAfter48 = () => {
        let amount = Math.round((reservation?.payment?.firstRent + (reservation?.payment?.extraTenantsValue || 0) - reservation?.inlifeCommissionVat) * 100) / 100;
        return amount > 0 ? amount : 0;
    }

    const acceptReservation = async () => {
        setLoading(true)
        await reservationServices.acceptReservation(reservation_id);
        reservation.acceptedDate = new Date();
        reservation.currentStatus = (reservation.type === "booking" ? "payment" : "paid");
        setReservation(reservation);
        setReservationStatus(reservation.type === "booking" ? "waitingPay" : "confirmed")
        updateReservation(reservation);
        setLoading(false);
        setShowSuccessMessage("booking_accepted");
        setUser(prev => ({...prev, pendingReservationRequests: prev.pendingReservationRequests === 0 ? 0 : prev.pendingReservationRequests - 1}));
        let listing_id = reservation.property?.id;
        if (reservation.property?.accommodation !== "apartment") {
            listing_id += "_" + (reservation.property?.room?.id || reservation.room_id);
        }
        if (reservation.type === "instant booking") {
            //aceitação do instant booking implica confirmação da reserva
            setUser(prev => ({...prev, acceptedBookings: prev.acceptedBookings + 1}));
        } else {
            setUser(prev => ({...prev, pendingReservations: prev.pendingReservations + 1}));
        }

        if(window.dataLayer) {
            const reservationData = {
                id: reservation.id,
                landlordEmail: reservation.landlord.email,
                acceptedDate: reservation.acceptedDate,
                contractEndDate: reservation.contractEndDate,
                contractStartDate: reservation.contractStartDate,
                moveIn: reservation.moveIn,
                moveOut: reservation.moveOut,
                currentStatus: reservation.currentStatus,
                numTenants: reservation.numTenants,
                requestDate: reservation.requestDate,
                status: reservation.status,
                type: reservation.type,
                tenantEmail: reservation.user.email,
                tenantName: reservation.user.name,
                tenantNationality: reservation.user_info.nationality,
            }

            window.dataLayer.push({
                event: "reservationAccepted",
                ...reservationData
            });
        }
    }

    const rejectReservation = (motive, moveIn, moveOut) => {
        let listing_id = reservation.property?.id;
        if(reservation.property?.accommodation !== "apartment") {
            listing_id += "_" + (reservation.property?.room?.id || reservation.room_id);
        }
        if (motive) {
            setReservationStatus("rejected")
            reservation.currentStatus = "rejected"
            reservation.rejectedDetails = {};
            reservation.rejectedDetails.motive = motive;
            setShowSuccessMessage("booking_rejected");
            setUser(prev => ({...prev, rejectedReservations: prev.rejectedReservations + 1}));
        } else {
            reservation.acceptedDate = new Date();
            if (moveIn) reservation.moveIn = moment(moveIn);
            if (moveOut) reservation.moveOut = moment(moveOut);
            reservation.needsStudentAcceptance = true;
            reservation.suggestionType = "dates";
            setReservation(reservation);
            setReservationStatus("waitingStudentAcceptance")
            setUser(prev => ({...prev, pendingReservations: prev.pendingReservations + 1}));
        }
        setUser(prev => ({...prev, pendingReservationRequests: prev.pendingReservationRequests - 1}));
        updateReservation(reservation);
    }

    useEffect(() => {
        if (reservation) {
            let status;
            switch (reservation.currentStatus) {
                case "request":
                    status = "requested";
                    break;
                case "paid":
                    status = (reservation.acceptedDate ? "confirmed" : "requested");
                    break;
                case "payment":
                    status = "waitingPay";
                    break;
                case "rejected":
                    status = "rejected";
                    break;
                case "expired":
                    status = "rejected";
                    break;
                case "cancelled":
                    status = "cancelled";
                    break;
                case "request_cancellation":
                    status = "cancellation";
                    break;
                default:
                    status = "requested";
                    break;
            }
            setReservationStatus((reservation.needsStudentAcceptance && !(status === "rejected" || reservation.cancellation)) ? "waitingStudentAcceptance" : status);
        }
    }, [reservation])


    const getCancellationRefundOption = () => {
        let days = moment(reservation.moveIn).startOf("day").diff(moment(reservation.cancellation?.requestDate).startOf("day"), "days");
        if (days > 30) {
            return 1;
        } else {
            return (days <= 0 ? 3 : 2);
        }
    }

    const pages = [
        {
            label: 'main_page.reservations.requests.label',
            header: 'main_page.reservations.requests.page_header',
            path: 'requests',
            component: ReservationList,
            filter: 'requests'
        },
        {
            label: 'main_page.reservations.pending.label',
            header: 'main_page.reservations.pending.page_header',
            path: 'pending',
            component: ReservationList,
            filter: 'pending'
        },
        {
            label: 'main_page.reservations.confirmed.label',
            header: 'main_page.reservations.confirmed.page_header',
            path: 'confirmed',
            component: ReservationList,
            filter: 'confirmed'
        },
        {
            label: 'main_page.reservations.rejected.label',
            header: 'main_page.reservations.rejected.page_header',
            path: 'rejected',
            component: ReservationList,
            filter: 'rejected'
        },
        {
            label: 'main_page.reservations.cancelled.label',
            header: 'main_page.reservations.cancelled.page_header',
            path: 'cancelled',
            component: ReservationList,
            filter: 'cancelled'
        }
    ]

    let preWarningDays = useMemo(() => {
        let days;
        if (reservation?.cancellation) {
            let requestDate = reservation.cancellation?.requestDate;
            let startDate = moment(requestDate).isBefore(moment(reservation.moveIn)) ? reservation.contractStartDate : reservation.moveIn;
            days = moment(startDate).startOf("day").diff(moment(requestDate).startOf("day"), "days");
            return days < 0 ? 0 : days;
        }
    }, [reservation]);

    const hasPenalty = useMemo(() => {
        if (reservation && reservation?.property && reservation.cancellation)
            return (reservation.property.refundPolicy === 0 || preWarningDays < reservation.property.refundPolicy) && reservation.cancellation.type.includes("landlord")
    }, [reservation])

    const toggleDrawer = (drawerId) => {
        setDrawerStates((prevState) => ({
            ...prevState,
            [drawerId]: !prevState[drawerId],
        }));
    };

    return (
        <div className={"ReservationDetailsContainer"}>
            <div className={"ReservationDetailsNavBar__mobile"}>
                <FiltersContainer pages={pages} type={"reservations"}/>
            </div>
            <div className="ReservationDetailsContainer__header">
                <NavBarGoBack />
            </div>

            <div className="ReservationDetails">
                    {!reservation || loading ? <Loading/> :
                        <>
                            <PaymentSuccessModal open={openPaymentSuccessModal} setOpen={setOpenPaymentSuccessModal}
                                                 t={t}/>
                            <ActionDialog type={showSuccessMessage}
                                          open={showSuccessMessage}
                                          setOpen={setShowSuccessMessage}
                                          title={t("action_dialog." + showSuccessMessage, {tenantName: (reservation.user_info?.name || reservation.user.name).split(" ")[0]})}
                                          message={showSuccessMessage === "booking_accepted" ?
                                              (reservation.type === "booking" ? t("action_dialog.waiting_payment") : null)
                                              : <Trans i18nKey={"action_dialog." + showSuccessMessage + "-message"}
                                                       values={{
                                                           tenantName: (reservation.user_info?.name || reservation.user.name).split(" ")[0],
                                                           moveIn: moment(reservation.moveIn).locale(localStorage.getItem('i18nextLng').split('-')[0] || "en").format("DD MMM YYYY"),
                                                           moveOut: moment(reservation.moveOut).locale(localStorage.getItem('i18nextLng').split('-')[0] || "en").format("DD MMM YYYY")
                                                       }}
                                                       components={{blue: <span style={{color: "#57B4F2"}}/>}}/>
                                          }/>
                        <div className="ReservationDetails__header">
                            <h3>
                                {reservation && !loading ? (<Trans
                                            i18nKey={translationDirc + "reservation"}
                                    values={{ name: reservation.user_info?.name?.trim().split(" ")[0] || reservation.user.name.trim().split(" ")[0] }}
                                />) : null}
                            </h3>


                        </div>

                        <div className="ReservationDetails__main-content">
                            <div className='ReservationDetails__house-reservation card'>
                                <div className="ReservationDetails__reservation-info">
                                    <div className={"ReservationDetails__property"}>
                                        <div className={"ReservationDetails__property-photo"}
                                            onClick={() => window.open(propertyServices.generateStudentUrl(reservation.property?.id, getRoom().id), "_blank")}
                                            style={{backgroundImage: `url('${(propertyImage)}')`}}
                                        />
                                        <div className={"ReservationDetails__property-details"}>
                                            <span onClick={() => window.open(propertyServices.generateStudentUrl(reservation.property?.id, getRoom().id), "_blank")}>
                                                <strong>{clampText((reservation.property.internal_name ? reservation.property.internal_name : reservation.property.title?.[localStorage.getItem('i18nextLng').split('-')[0]]), 36)}
                                                </strong>
                                            </span>
                                            <span>{reservation.property.address}{reservation.property.floor ? ", " + reservation.property.floor : ""}</span>
                                            <span>ID {reservation.property.id?.split("-")[0]} {('· ' + (roomName ? roomName : t('main_page.my_visits.visit_card.room_n') + roomNumber))} {getRoom() && '· ' + t('up_to').toLowerCase() + " " + getRoom().numPeople + " " + t(`person${getRoom().numPeople > 1 ? "_plural" : ""}`)}</span>
                                            <span className="rent">
                                                <span>{t('main_page.my_visits.visit_card.monthly_rent')}</span>
                                                <h5>{monthlyRent}</h5>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="ReservationDetails__reservation-info__separator">
                                        <div className="ReservationDetails__reservation-info__separator__line">&nbsp;</div>
                                        <div className="ReservationDetails__reservation-info__icon">
                                            <CompareArrowsIcon width={24} />
                                        </div>
                                        <div className="ReservationDetails__reservation-info__separator__line">&nbsp;</div>
                                    </div>

                                    <div className={"ReservationDetails__tenant"}>

                                        <div className="ReservationDetails__tenant__avatar">
                                            <div className={"avatar-container"}
                                                style={{ backgroundImage: `url(${reservation.user.photo || ((reservation.user_info?.gender || reservation.user.personalDetails.gender) === "female" ? avatarFemale : avatarMale)})` }}>
                                                <div className={'RoundedCircleFlag'}>
                                                    <div className={'CountryFlagCircle'}
                                                        style={{ backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${reservation.user.personalDetails.nationality.toUpperCase()}@2x.png?raw=true)` }} />
                                                </div>
                                            </div>
                                            <h5>{reservation.user_info?.name?.trim().split(" ")[0] || reservation.user.name?.trim().split(" ")[0]}</h5>
                                        </div>

                                        <div className="ReservationDetails__tenant__profile">
                                            <span>{t(reservation.user_info?.gender || reservation.user.personalDetails.gender) + " · "}{reservation.user.personalDetails.age + " " + t('years') + " · " + t("country." + (reservation.user_info?.nationality || reservation.user.personalDetails.nationality))}</span>
                                            <span>{t('main_page.my_visits.visit_card.occupation.' + reservation.user.personalDetails.occupation)} {t('at')} {reservation.user.personalDetails.occupation === "student" ? reservation.user.personalDetails.university?.split(" - ")[0] : reservation.user.personalDetails.occupation_detail}</span>
                                            <span>{t(translationDirc + "reservation_for")} {reservation.numTenants + " " + t(`person${reservation.numTenants > 1 ? "_plural" : ""}`)}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className={"ReservationDetails__actions-card card"}>
                                {reservationStatus && reservationStatus !== "requested" && <div className="ReservationDetails__actions-card__request-state">
                                    <div className={`status-bar ${reservationStatus}`}>
                                        <div className={`status-bar__icon ${reservationStatus}`}>
                                            {(reservationStatus === "waitingPay" ||
                                                reservationStatus === "waitingStudentAcceptance")
                                                && <HourglassBottomIcon />
                                            }
                                            {reservationStatus === "cancelled" && <NotInterestedIcon />}
                                            {reservationStatus === "cancellation" && <div className={"cancellation-icon_wrapper"}><CancelIcon width={26} height={26}/></div>}
                                            {reservationStatus === "rejected" && <ClearIcon />}
                                            {reservationStatus === "confirmed" && <CheckIcon />}
                                            {reservationStatus === "scheduled" && <EventAvailableIcon />}
                                        </div>
                                        <div className="status-bar__info">
                                            <h2>{t(translationDirc + 'status_bar.' + reservationStatus + '_title') + " "}</h2>
                                        </div>
                                    </div>
                                </div>}

                                {reservationStatus === "requested" && <>
                                    <div className={"state"}>
                                            <div>
                                                <CreditCardIcon />
                                                {t("main_page.reservations.reservation_details.payment.label")}
                                                <span>{t('main_page.reservations.reservation_details.type_' + (reservation.type === "booking" ? "booking" : "instant"))}</span>
                                            </div>
                                            {reservationStatus === "requested" &&
                                                <div>
                                                    <TimerIcon />
                                                    {t("main_page.reservations.reservation_details.expires_in")}
                                                    <ExpirationCountdown targetTimestamp={new Date(getExpirationDate(reservation.requestDate))} />
                                                </div>
                                            }
                                    </div>

                                    <hr className='separator' />

                                    <div className={"actions"}>
                                            {!reservation.suggestionType && <button
                                                onClick={() => setOpenSuggestDates(true)}
                                                className={"btn btn-secondary"}>{t('main_page.reservations.reservation_details.suggest_new_dates')}</button>}
                                            {!reservation.suggestionType && <button
                                                onClick={() => setOpenSuggestProperty(true)}
                                                className={"btn btn-secondary"}>{t('main_page.reservations.reservation_details.suggest_new_property')}</button>}
                                            <button
                                                onClick={acceptReservation}
                                                className={"btn btn-primary"}>{t('main_page.reservations.reservation_details.accept_reservation')}</button>
                                    </div>

                                    <div className={"info"}>
                                        <div className="message">
                                            <span>
                                                <Trans i18nKey={'main_page.reservations.reservation_details.accept_info.' + reservation.type}
                                                    values={{ tenantName: (reservation.user_info?.name || reservation.user.name).split(" ")[0] }}
                                                    components={<strong />}
                                                />
                                            </span>
                                        </div>
                                        <hr className='separator' />
                                        <div className="second-action">
                                            <button onClick={() => setOpenRejectModal(true)} className="btn btn-tertiary">
                                                {t('main_page.reservations.reservation_details.reject')}
                                            </button>
                                        </div>
                                    </div>

                                        <SuggestNewDateModal open={openSuggestDates}
                                            setOpen={setOpenSuggestDates}
                                            reservation={reservation} room={getRoom()}
                                            rejectReservation={rejectReservation}
                                        setShowActionDialog={setShowSuccessMessage}
                                    />

                                        <SuggestPropertyModal open={openSuggestProperty}
                                            setOpen={setOpenSuggestProperty}
                                            room={getRoom()} room_id={reservation.room_id}
                                            request={reservation}
                                            rejectReservation={rejectReservation}
                                            currentRent={parseFloat(monthlyRent.includes("-") ? monthlyRent.split("-")[0] : monthlyRent.slice(0, -1))}
                                        type={"reservation"}
                                    />
                                </>}

                                <RejectReservationModal open={openRejectModal} setOpen={setOpenRejectModal}
                                    reservation={reservation} room={getRoom()}
                                    rejectReservation={rejectReservation}
                                    history={history} />

                                {(reservationStatus === "rejected" || reservationStatus === "cancelled") && <>
                                    <div className={"state"}>
                                        <div>
                                            <NotInterestedIcon />
                                            {reservationStatus !== "rejected" ? <>
                                                {t("main_page.reservations.reject_reservation.cancelled_by")}
                                                <span>{t("main_page.reservations.reject_reservation." + reservation.cancellation.type.split("_")[0])}</span>
                                            </> : <span>{t("main_page.reservations.reject_reservation." + reservationStatus)}</span>}
                                        </div>
                                        <div>
                                            <ReasonMessageIcon />
                                            {t("main_page.my_visits.visit_card.motive")}
                                            <span>{(reservation.currentStatus === "expired" && !reservation.acceptedDate) ?
                                                t("main_page.reservations.reject_reservation.motive.not_accepted") : (
                                                    (reservation.rejectedDetails || reservation.cancellation) ?
                                                        (reservation.rejectedDetails?.motive ?
                                                            t("main_page.reservations.reject_reservation.motive." + reservation.rejectedDetails.motive + '_past')
                                                            : t("main_page.reservations.reject_reservation.motive." + (reservation.cancellation.motive || "unsolved_issues")))
                                                        : t("main_page.reservations.reject_reservation.motive.not_paid")
                                                )}
                                            </span>
                                        </div>

                                    </div>
                                </>}

                                {reservationStatus === "confirmed" && moment(reservation.moveIn).endOf("day").isSameOrBefore(moment()) ? <>
                                    <div className="message">
                                        <span>
                                            <Trans i18nKey={translationDirc + 'protection_expired'}
                                                components={{
                                                    a: <a className="blue-link-label"
                                                        target="_blank"
                                                        href={"https://inlifehousing.com/terms-conditions/"}
                                                        rel="noopener noreferrer"
                                                    />
                                                }}
                                            />
                                        </span>
                                    </div>
                                    <hr className='separator' />
                                </> : reservationStatus === "confirmed" && !moment(reservation.moveIn).endOf("day").isSameOrBefore(moment()) && <>
                                    <div className="message">
                                        <div>
                                            <span>
                                                <Trans i18nKey={translationDirc + 'cancellationTip'}
                                                    components={{ strong: <strong /> }}
                                                /></span>
                                            <a className="blue-link-label"
                                                target="_blank"
                                                href={"https://inlifehousing.com/terms-conditions/"}
                                                rel="noopener noreferrer"
                                            >
                                                {t("main_page.reservations.cancel_reservation.terms_link")}
                                            </a>
                                        </div>
                                        </div>
                                    <hr className='separator' />
                                </>}
                                {reservationStatus === "cancellation" && <>
                                    <div className={"cancellation-request_info"}>
                                        <Trans i18nKey={"main_page.reservations.reservation_details.requested_cancellation_message"}
                                               components={{s:<strong />}} values={{tenantName: (reservation.user_info?.name || reservation.user.name).split(" ")[0]}}/>
                                    </div>
                                    <hr className='separator' />
                                </>}

                                {["cancellation", "confirmed"].includes(reservationStatus) &&
                                    <div className="state">
                                        <div className="user-info">
                                            <div className="user-contact">
                                                <p>{t(translationDirc + "phone")}</p>
                                                <label>{`${reservation.user.personalDetails.dialCode} ${reservation.user.personalDetails.phone}`}</label>
                                            </div>
                                            <div className="user-contact">
                                                <p>{t(translationDirc + "email")}</p>
                                                <label>{reservation.user.email}</label>
                                            </div>
                                        </div>
                                        <hr className='separator' />
                                        <div className="user-actions">
                                            <div className="btn-container">
                                                {reservationStatus === "confirmed" && <div className="btn-container">

                                                    {reservationStatus === "confirmed" && (moment(reservation.moveIn).endOf("day").isAfter(moment()) &&
                                                        <button className="btn btn-secondary"
                                                                onClick={() => {
                                                                    history.push("/reservations/" + reservation.id + "/cancel")
                                                                }}
                                                        >
                                                            {t(translationDirc + "cancellation_request")}
                                                        </button>
                                                    )}
                                                    <a className="btn btn-primary" href={"mailto:" + reservation.user.email}>
                                                        {t(translationDirc + "contact")}
                                                    </a>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {!["requested", "rejected", "cancelled", "cancellation", "confirmed"].includes(reservationStatus) &&
                                    <>
                                    <div className="message">
                                        <span>
                                            {reservationStatus === "waitingPay" ? <Trans i18nKey={translationDirc + 'payment.pending'}
                                                components={{ strong: <strong /> }}
                                            /> : reservation?.suggestionType === "dates" ? <Trans i18nKey={translationDirc + "newDate"}
                                                components={<strong />}
                                            /> : <Trans i18nKey={translationDirc + "newProperty"}
                                                components={<strong />}
                                            />}
                                        </span>
                                    </div>
                                    <hr className='separator' />
                                    <div className={"state"}>
                                            <div>
                                                <CreditCardIcon />
                                                {t("main_page.reservations.reservation_details.payment.label")}
                                                <span>{t('main_page.reservations.reservation_details.type_' + (reservation.type === "booking" ? "booking" : "instant"))}</span>
                                        </div>
                                        <div>
                                            <TimerIcon />
                                            {t("main_page.reservations.reservation_details.expires_in")}
                                            <ExpirationCountdown targetTimestamp={new Date(getExpirationDate(reservation.requestDate))} />
                                        </div>
                                    </div>
                                    <hr className='separator' />
                                        <div className="second-action">
                                            <button onClick={() => setOpenRejectModal(true)} className="btn btn-tertiary">
                                                {t(`main_page.reservations.reservation_details.${reservation.needsStudentAcceptance ? "cancelAndReject" : "reject"}`)}
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>


                        </div>


                        <div className="ReservationDetails__wrapper">
                            <div className={"ReservationDetails__content"}>

                                {reservationStatus === "cancelled" && <div className="ReservationDetails__card">
                                    <h3>{t("main_page.reservations.cancel_reservation.reservation_info.applied_policy")}</h3>
                                    <div className={"ReservationDetails__cancellation-details card"}>
                                        <CancellationTimeline reservation={reservation} />

                                        <div className="cancellation-details">
                                            <div className="cancellation-details__header">
                                                <NoMeetingRoomIcon />
                                                <h5>{t("main_page.reservations.cancel_reservation.reservation_info.reservationCancellation")}</h5>
                                            </div>

                                            <div className="cancellation-details__content">

                                                {reservation.cancellation.type !== "inlife" && <div className="cancellation-details__content__policy-container">
                                                    <span>
                                                        <Trans
                                                            i18nKey={"main_page.my_ads.create_ad.house_details.landlord_rules.level4.refundPolicy.infoLandlord." +
                                                                reservation.property.refundPolicy
                                                            }
                                                            components={{ strong: <strong /> }}
                                                        />
                                                    </span>
                                                </div>}

                                                {reservation.cancellation.type === "tenant_after" && reservation.cancellation.answer &&
                                                    reservation.cancellation.answer !== "not_accepted" && <div className={"response-info"}>
                                                        {t("main_page.reservations.cancel_reservation.reservation_info.response_info")}
                                                    </div>}

                                                {reservation.cancellation.type === "tenant_after" &&
                                                    !reservation.cancellation.answer && <div className={"response-info"}>
                                                        {t("main_page.reservations.cancel_reservation.reservation_info.response_warning")}
                                                    </div>}

                                                <div className="cancellation-details__info">
                                                    <div className="cancellation-details__info__item">
                                                        <label>{t("main_page.reservations.cancel_reservation.reservation_info.reservation_n")}</label>
                                                        <span>{reservation_id.split("-")[0]}</span>
                                                    </div>

                                                    <div className="cancellation-details__info__item">
                                                        <label>{t("main_page.reservations.cancel_reservation.reservation_info.cancellation_motive")}</label>
                                                        <span>{t("main_page.reservations.reject_reservation.motive." + (reservation.cancellation.motive || "unsolved_issues"))}</span>
                                                    </div>

                                                    <div className="cancellation-details__info__item">
                                                        <label>{t("main_page.reservations.cancel_reservation.reservation_info.value_returned")}</label>
                                                        <span>€{reservation.cancellation?.type === "inlife" ?
                                                            (reservation.cancellation.paymentAmount - reservation.serviceFee).toFixed(2) :
                                                            (reservation.cancellation.type.includes("landlord") ?
                                                                (reservation.payment.firstRent + reservation.payment.extraTenantsValue).toFixed(2) : 0)}</span>
                                                    </div>


                                                    {hasPenalty ? <div className="cancellation-details__info__item">
                                                        <label>{t("main_page.reservations.cancel_reservation.penalty_no_percentage")}</label>
                                                        <span>2%</span>
                                                    </div> : <div className="cancellation-details__info__item">
                                                        <label>{t("main_page.reservations.cancel_reservation.reservation_info.service_fee")}</label>
                                                        <span>€{(reservation.cancellation.type.split("_")[0] === "landlord" ||
                                                            reservation.cancellation.answer === "accepted_refunded" ||
                                                            (reservation.cancellation.type === "tenant_after" &&
                                                                moment(reservation?.cancellation?.requestDate)?.startOf("day")?.isSameOrAfter(moment(reservation?.moveIn)?.startOf("day"))) &&
                                                            moment(reservation?.cancellation?.requestDate)?.diff(moment(reservation?.moveIn), "hour") <= 24) ? reservation.inlifeCommissionVat : 0}</span>
                                                    </div>}

                                                    {reservation.cancellation.type.includes("tenant") && <span>{t("main_page.reservations.cancel_reservation.reservation_info.landlord_refund")} €{preWarningDays < reservation.property.refundPolicy || reservation.property.refundPolicy === 0 ? (reservation.payment.firstRent - (reservation.payment.firstRent * (reservation.landlordCommission * 0.01) * 1.23)) : 0}</span>}
                                                </div>
                                            </div>
                                        </div>

                                        {hasPenalty && <span className="penalty-info">
                                            {t("main_page.reservations.cancel_reservation.penalty_explanation")}
                                        </span>}

                                    </div>
                                </div>}

                                <div className="ReservationDetails__card">
                                    <h3>{t(translationDirc + "stayDetails")}</h3>
                                    <div className="card">
                                        <div className="ReservationDetails__stay-details">
                                            <div className="ReservationDetails__stay-details__title">
                                                <ArrowForwardIcon width={36} />
                                                {t(translationDirc + 'move_in')}
                                            </div>
                                            {reservation.moveIn && new Date(reservation.moveIn).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" })}
                                        </div>
                                        <div className="ReservationDetails__stay-details">
                                            <div className="ReservationDetails__stay-details__title">
                                                <ArrowBackwardIcon width={36} />
                                                {t(translationDirc + 'move_out')}
                                            </div>
                                            {reservation.moveOut && new Date(reservation.moveOut).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" })}
                                        </div>
                                        <div className="ReservationDetails__stay-details">
                                            <div className="ReservationDetails__stay-details__title">
                                                <DateRangeCalendarIcon width={36} />
                                                {t(translationDirc + 'total_stay')}
                                            </div>
                                            {reservation.dateRents && `${reservation.dateRents.length} ${t(translationDirc + 'months')}`}
                                        </div>
                                        <div className="ReservationDetails__stay-details">
                                            <div className="ReservationDetails__stay-details__title">
                                                <PersonAddIcon width={36} />
                                                {t(translationDirc + 'extra_tenants')}
                                            </div>
                                            {reservation.numTenants && reservation.numTenants > 1 ? reservation.numTenants - 1 : t(translationDirc + 'noExtraTenant')}
                                        </div>
                                        {reservation.type === "booking" && <>
                                            <hr className='stay-details__separator' />
                                            <div className="ReservationDetails__stay-details__last">
                                                <div className="ReservationDetails__stay-details__title__last">
                                                    <TrendingUpIcon />
                                                    {t(translationDirc + 'potential_income')}
                                                </div>
                                                {reservation.totalContractValue && new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'EUR' }).format(reservation.totalContractValue)}
                                            </div>
                                        </>}
                                    </div>
                                </div>

                                {(reservation.user?.presentationVideo?.processed && reservation.user?.presentationVideo?.validated) || reservation.user?.personalDetails?.extraInfo ? <div className='ReservationDetails__card'>
                                    <h3>
                                        <Trans i18nKey={translationDirc + "meet"}
                                            values={{ name: (reservation.user_info?.name || reservation.user.name).split(" ")[0]}}
                                        />
                                    </h3>
                                    <div className='card'>
                                        <div className="ReservationDetails__meet">
                                            <div className="ReservationDetails__meet__info">
                                                {reservation.user?.personalDetails?.extraInfo && <div className="ReservationDetails__meet__about">
                                                    <h5>{t("main_page.reservations.reservation_details.aboutTenant")}</h5>
                                                    <ClampedText text={redactPhoneAndEmail(reservation.user.personalDetails.extraInfo)} />
                                                </div>}
                                                {(reservation.user?.presentationVideo?.processed && reservation.user?.presentationVideo?.validated) &&
                                                    <div className="ReservationDetails__meet__video-bio">
                                                        <h5>{t("main_page.reservations.reservation_details.video")}</h5>
                                                        <video controls>
                                                            <source src={reservation.user.presentationVideo.url} type="video/mp4" />
                                                            <p>Your browser doesn't support HTML video.</p>
                                                        </video>
                                                    </div>
                                                }
                                            </div>
                                            {/* TO BE IMPLEMENTED
                                            <div className="ReservationDetails__meet__application">
                                                <h5>{t("main_page.reservations.reservation_details.tenant_application")}</h5>
                                                <ClampedText text={`My name is Pierre, and I'm a 21-year-old French student studying at the Sorbonne in Paris.
                                                    I'm currently looking for a place to rent as I finish up my studies.
                                                    I come from a small town in the south of France, and I've always loved exploring new places and meeting new people.
                                                    That's one of the reasons I decided to move to Paris for school.`} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div> : null}
                            </div>

                            <div className="ReservationDetails__content">

                                <div className="ReservationDetails__card">
                                    <h3>{t(translationDirc + "contract.requestDetails")}</h3>
                                    <div className="card card__payment">

                                        <div className="sub-card">
                                            <div className="sub-card__header">
                                                <AssignmentIcon />
                                                {t(translationDirc + "contract.contractDetails")}
                                            </div>
                                            <div className="sub-card__body">
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + "contract.startDate")}</p>
                                                    <strong>{reservation.contractStartDate && new Date(reservation.contractStartDate).toLocaleDateString(i18n.language, { year: 'numeric', month: 'short', day: 'numeric' })}</strong>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + "contract.endDate")}</p>
                                                    <strong>{reservation.contractEndDate && new Date(reservation.contractEndDate).toLocaleDateString(i18n.language, { year: 'numeric', month: 'short', day: 'numeric' })}</strong>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <p>{t('main_page.reservations.reservation_details.bills.label')}</p>
                                                    <div className="sub-card__body__item__icon">

                                                        {isMobile ? <>
                                                            <InfoCircleIcon onClick={() => toggleDrawer("bills")} />
                                                            <Drawer isOpen={drawerStates["bills"]} onClose={() => toggleDrawer("bills")}>
                                                                <div className="bills-tooltip">
                                                                    <div className="bills-tooltip__header">
                                                                        {!reservation.property?.billsIncluded && <>
                                                                            <p>{t(translationDirc + "bills.notIncludedLabel")}</p>
                                                                            <span>{t(translationDirc + "bills.notIncludedDescription")}</span>
                                                                        </>}
                                                                        {reservation.property?.billsIncluded && !(reservation.property?.billsSpecific && !(reservation.property?.billsMax || reservation.property?.billsMax <= 0)) && <>
                                                                            <p>{t(translationDirc + "bills.allBillsLabel")}</p>
                                                                            <span>{t(translationDirc + "bills.allBillsDescription")}</span>
                                                                        </>}
                                                                        {reservation.property?.billsIncluded && reservation.property?.billsMax > 0 ? <>
                                                                            <p>{t(translationDirc + "bills.includedLabel")}</p>
                                                                            <span><Trans i18nKey={translationDirc + "bills.includedDescription"} values={{ value: reservation.property?.billsMax }} /></span>
                                                                        </> : reservation.property?.billsSpecific?.length > 0 && <>
                                                                            <p>{t(translationDirc + "bills.specificIncludedLabel")}</p>
                                                                            <span>{t(translationDirc + "bills.specificIncludedDescription")}</span>
                                                                        </>}
                                                                    </div>
                                                                    {reservation.property?.billsIncluded && reservation.property?.billsSpecific?.length > 0 ? <div>
                                                                        {reservation.property?.billsSpecific.map(bill => {
                                                                            const { icon, translationKey } = PROPERTY_BILLS[bill];

                                                                            return (
                                                                                <div className="bills-tooltip__content-item">
                                                                                    <div className="bills-tooltip__content-item__icon">
                                                                                        {icon}
                                                                                    </div>
                                                                                    {t(translationDirc + translationKey)}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div> : null}
                                                                </div>
                                                            </Drawer>
                                                        </> : <Tooltip position="left">
                                                                <Tooltip.Icon>
                                                                    <InfoCircleIcon />
                                                                </Tooltip.Icon>
                                                                <Tooltip.Content className="bills-tooltip">
                                                                <div className="bills-tooltip__header">
                                                                        {!reservation.property?.billsIncluded && <>
                                                                        <p>{t(translationDirc + "bills.notIncludedLabel")}</p>
                                                                        <span>{t(translationDirc + "bills.notIncludedDescription")}</span>
                                                                    </>}
                                                                        {reservation.property?.billsIncluded && !(reservation.property?.billsSpecific && !(reservation.property?.billsMax || reservation.property?.billsMax <= 0)) && <>
                                                                            <p>{t(translationDirc + "bills.allBillsLabel")}</p>
                                                                            <span>{t(translationDirc + "bills.allBillsDescription")}</span>
                                                                        </>}
                                                                        {reservation.property?.billsIncluded && reservation.property?.billsMax > 0 ? <>
                                                                        <p>{t(translationDirc + "bills.includedLabel")}</p>
                                                                            <span><Trans i18nKey={translationDirc + "bills.includedDescription"} values={{ value: reservation.property?.billsMax }} /></span>
                                                                        </> : reservation.property?.billsSpecific?.length > 0 && <>
                                                                        <p>{t(translationDirc + "bills.specificIncludedLabel")}</p>
                                                                        <span>{t(translationDirc + "bills.specificIncludedDescription")}</span>
                                                                    </>}

                                                                    </div>
                                                                    {reservation.property?.billsIncluded && reservation.property?.billsSpecific?.length > 0 ? <div>
                                                                        {reservation.property?.billsSpecific.map(bill => {
                                                                            const { icon, translationKey } = PROPERTY_BILLS[bill];

                                                                            return (
                                                                                <div>
                                                                                    <div className="bills-tooltip__content-item">
                                                                                        <div className="bills-tooltip__content-item__icon">
                                                                                            {icon}
                                                                                        </div>
                                                                                        {t(translationDirc + translationKey)}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div> : null}
                                                                </Tooltip.Content>
                                                        </Tooltip>}

                                                        <strong>
                                                            {!reservation.property?.billsIncluded ? t(translationDirc + 'bills.notIncluded') :
                                                                (reservation.property?.billsIncluded && !reservation.property?.billsSpecific?.length > 0 ?
                                                                    t(translationDirc + 'bills.included')
                                                                    : t(translationDirc + 'bills.specificIncluded')
                                                                )
                                                            }
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <p>{t('main_page.reservations.reservation_details.rentType')}</p>
                                                    <strong>{t('main_page.my_ads.create_ad.house_details.landlord_rules.level4.incompleteRentType.' + reservation.payment?.rentType + ".label")}</strong>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + "contract.cancel")}</p>
                                                    <strong>
                                                        {(reservation.property.refundPolicy && parseInt(reservation.property.refundPolicy) !== 0) ?
                                                            <Trans i18nKey={"main_page.reservations.cancel_reservation.policy.refundable"} values={{ days: reservation.property.refundPolicy }} /> :
                                                            t("main_page.reservations.cancel_reservation.policy.notRefundable")}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sub-card">
                                            <div className="sub-card__header">
                                                <AssignmentIcon />
                                                {t(translationDirc + "payment.details")}
                                            </div>
                                            <div className="sub-card__body">
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + "payment.rentToInlife")}</p>
                                                    <strong>€{(reservation.payment.firstRent + (reservation.payment.extraTenantsValue || 0)).toFixed(2)}</strong>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <div className="vat-item">
                                                        <span>{t(translationDirc + 'serviceFee')}</span>
                                                        <span className="extra-info">{t(translationDirc + (reservation.hasIVA ? "iva" : "no_iva"))}</span>
                                                    </div>
                                                    <strong>-€{reservation.inlifeCommissionVat}</strong>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + 'transfer')}</p>
                                                    <div className="sub-card__body__item__icon">

                                                        {getPaidAfter48() > 0 && isMobile ? <>
                                                            <InfoCircleIcon onClick={() => toggleDrawer("fee")} />
                                                            <Drawer isOpen={drawerStates["fee"]} onClose={() => toggleDrawer("fee")}>
                                                                <div className="drawer-content">
                                                                    <p>{t(translationDirc + 'transfer')}</p>
                                                                    <span><Trans i18nKey={translationDirc + "48h"} values={{ value: getPaidAfter48() }} /></span>
                                                                    {!reservation.hasIVA ? <span>{t(translationDirc + "no_iva_48")}</span> : null}
                                                                </div>
                                                            </Drawer>
                                                        </> : getPaidAfter48() > 0 && <Tooltip position="left">
                                                                <Tooltip.Icon>
                                                                    <InfoCircleIcon />
                                                                </Tooltip.Icon>
                                                                <Tooltip.Content>
                                                                    <span><Trans i18nKey={translationDirc + "48h"} values={{ value: getPaidAfter48() }} /></span>
                                                                {!reservation.hasIVA ? <span>{t(translationDirc + "no_iva_48")}</span> : null}
                                                                </Tooltip.Content>
                                                        </Tooltip>}

                                                        <strong>€{getPaidAfter48()}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sub-card">
                                            <div className="sub-card__header">
                                                <PortraitIcon />
                                                {t(translationDirc + 'paidToYou')}
                                            </div>
                                            <div className="sub-card__body">
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + "payment.method")}</p>
                                                    <strong>
                                                        {t('main_page.reservations.reservation_details.type_' +
                                                            (reservation.type === "booking" ? "booking" : "instant")
                                                        )}
                                                    </strong>
                                                </div>
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + 'securityDepositTitle')}</p>
                                                    <div className="sub-card__body__item__icon">

                                                        {isMobile ? <>
                                                            <InfoCircleIcon onClick={() => toggleDrawer("deposit")} />
                                                            <Drawer isOpen={drawerStates["deposit"]} onClose={() => toggleDrawer("deposit")}>
                                                                <div className="drawer-content">
                                                                    <p>{t(translationDirc + 'securityDepositTitle')}</p>
                                                                    <span>{t(translationDirc + "securityDepositDescription")}</span>
                                                                </div>
                                                            </Drawer>
                                                        </> : <Tooltip position="left">
                                                                <Tooltip.Icon>
                                                                    <InfoCircleIcon />
                                                                </Tooltip.Icon>
                                                                <Tooltip.Content>
                                                                {t(translationDirc + "securityDepositDescription")}
                                                                </Tooltip.Content>
                                                        </Tooltip>}
                                                        <strong>{reservation.securityDeposit}</strong>
                                                    </div>
                                                </div>
                                                {reservation?.property?.paidAtMovinDate?.includes("Last Rent") && <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + 'lastRentTitle')}</p>
                                                    <div className="sub-card__body__item__icon">

                                                        {isMobile ? <>
                                                            <InfoCircleIcon onClick={() => toggleDrawer("lastRent")} />
                                                            <Drawer isOpen={drawerStates["lastRent"]} onClose={() => toggleDrawer("lastRent")}>
                                                                <div className="drawer-content">
                                                                    <p>{t(translationDirc + 'lastRentTitle')}</p>
                                                                    <span>{t(translationDirc + "lastRentDescription")}</span>
                                                                </div>
                                                            </Drawer>
                                                        </> : <Tooltip position="left">
                                                                <Tooltip.Icon>
                                                                    <InfoCircleIcon />
                                                                </Tooltip.Icon>
                                                                <Tooltip.Content>
                                                                {t(translationDirc + "lastRentDescription")}
                                                                </Tooltip.Content>
                                                        </Tooltip>}


                                                        <strong>{reservation.lastRent}</strong>
                                                    </div>
                                                </div>}

                                                {reservation.payment.extraAdministrationFee > 0 && <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + 'adminFee')}</p>
                                                    <strong>{"€" + reservation.payment.extraAdministrationFee}</strong>
                                                </div>}
                                            </div>
                                        </div>

                                        <div className="sub-card">
                                            <div className="sub-card__header">
                                                <PaymentsIcon />
                                                {t(translationDirc + "payment.duringStay")}
                                            </div>
                                            <RentsTimeline rents={reservation.dateRents} />
                                        </div>

                                        <div className="sub-card">
                                            <div className="sub-card__header">
                                                <AssignmentReturnIcon />
                                                {t(translationDirc + "payment.returnedToTenant")}
                                            </div>
                                            <div className="sub-card__body">
                                                <div className='sub-card__body__item'>
                                                    <p>{t(translationDirc + 'securityDepositTitle')}</p>
                                                    <div className="sub-card__body__item__icon">

                                                        {isMobile ? <>
                                                            <InfoCircleIcon onClick={() => toggleDrawer("tenantDeposit")} />
                                                            <Drawer isOpen={drawerStates["tenantDeposit"]} onClose={() => toggleDrawer("tenantDeposit")}>
                                                                <div className="drawer-content">
                                                                    <p>{t(translationDirc + 'securityDepositTitle')}</p>
                                                                    <span>{t(translationDirc + "securityDepositDescription")}</span>
                                                                </div>
                                                            </Drawer>
                                                        </> : <Tooltip position="left">
                                                                <Tooltip.Icon>
                                                                    <InfoCircleIcon />
                                                                </Tooltip.Icon>
                                                                <Tooltip.Content>
                                                                {t(translationDirc + "securityDepositDescription")}
                                                                </Tooltip.Content>
                                                        </Tooltip>}
                                                        <strong>{reservation.securityDeposit}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {reservation.type === "instant" || reservationStatus === "confirmed" && <div className="payment-total-card">
                                            <h4>{t(translationDirc + "totalContractValue")}</h4>
                                            <h3>{reservation.totalContractValue &&
                                                new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'EUR' })
                                                    .format(reservation.totalContractValue)}
                                            </h3>
                                        </div>}

                                        </div>
                                    </div>


                                </div>
                            </div>

                        <div className="ReservationDetails__footer">
                            <button type="button" className="btn btn-ghost" onClick={() => {
                                document.getElementById("horizontalbar_content").scrollIntoView({ behavior: "smooth" })
                            }}>
                                <ArrowUpwardIcon />
                                {t("backToTop")}
                            </button>
                        </div>

                        </>
                    }
                </div>

        </div>
    )

}

export default ReservationDetails;
export {OutdatedAdInputs};

import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {ProvideAuth, useAuth} from './services/authServices';
import {persistor, store} from './redux/reducers';
import Start from './pages/Start';
import MainPage from './pages/MainPage';
import MainNavBar from './components/MainNavBar';
import Footer from './components/Footer';
import ReactGA from "react-ga4";
import Cookie from "cookie";

import {PopupDialogProvider} from './components/PopupDialog';
import AppBannerPopUp from './components/AppBannerPopUp';

import './services/i18n';

import './App.less';

function App(props) {
    useEffect(() => {
        ReactGA.initialize(window.location.host === "landlord.inlifehousing.com" ? "GTM-P3VFXDDH" : "G-SGQ8V16BHH");
        const _i = setInterval(() => {
            const cookies = Cookie.parse(document.cookie);
            if (cookies["_ga"]) {
                let _ga = cookies["_ga"].split(".");
                //console.log("GA initialized", cookies["_ga"]);
                ReactGA.set({userId: _ga[2]});
                ReactGA.set({user_id: _ga[2]});
                clearInterval(_i);
            }
        }, 500);

        if(process.env.USE_CRISP_CHAT) {
            window.CRISP_RUNTIME_CONFIG = {locale: localStorage.getItem("i18nextLng") || "en"};
            window.$crisp = [];
            $crisp.push(['safe', true])
            $crisp.push(["on", "session:loaded", () => {
                let close_crisp_icon = document.createElement("I");
                close_crisp_icon.classList.add("icon-close");
                let close_crisp = document.createElement("DIV");
                close_crisp.classList.add("crisp-close-button");
                close_crisp.appendChild(close_crisp_icon);
                let crisp_button_children = document.getElementById("crisp-chatbox").children[0].children;
                let crisp_button = crisp_button_children[crisp_button_children.length === 1 ? 0 : 1];
                crisp_button.appendChild(close_crisp);
                close_crisp.onclick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    $crisp.do("chat:hide");
                }
            }])
            window.CRISP_WEBSITE_ID = '4d555b08-b9e9-48ec-ac3e-b381c6db11ec';
            setTimeout(function () {
                let d = document;
                let s = d.createElement('script');
                s.src = 'https://client.crisp.chat/l.js';
                s.async = 1;
                d.getElementsByTagName('head')[0].appendChild(s);
            }, 100);
        }
    }, [])

    // useEffect(() => {
    //     console.log("cookies!", document.cookie);
    // }, [document.cookie])

    return (
        <ProvideAuth>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <Router>
                        <PopupDialogProvider>
                            <MainNavBar/>
                            <AppBannerPopUp />
                            <Switch>
                                <Route path="/start" component={Start}/>
                                <Route path="/" component={MainPage}/>
                                <Redirect to={'/'}/>
                            </Switch>
                        </PopupDialogProvider>
                    </Router>
                    <Footer/>
                    {/*<FooterNew displayApp={false}/>*/}
                </PersistGate>
            </Provider>
        </ProvideAuth>

    );
}

export default App;

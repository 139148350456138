import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CircleProgress} from 'react-gradient-progress';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

import '../../../../../../services/i18n';
import {useTranslation} from 'react-i18next';

import './style.less';
import PropertyDetails from './containers/house_details/PropertyDetails';
import LandlordRules from './containers/house_details/LandlordRules';
import PropertyAreas from './containers/house_details/PropertyAreas';
import CommonAreasDetails from './containers/house_details/CommonAreasDetails';
import Photographs from './containers/house_details/Photographs';
import RoomPhotos from './containers/room_details/RoomPhotos';
import RoomsMain from './containers/room_details/RoomsMain2.2_2.4.js';
import Resume from './containers/publish/Resume';
import houseDetailsIcon from './images/house_details.svg';
import roomDetailsIcon from './images/room_details.svg';
import publishIcon from './images/publish.svg';
import {get, post, propertyServices} from '../../../../../../services';
import PropertyType from './components/PropertyType';

import {displayFooterActions, propertyAdHistoryActions} from '../../../../../../redux/actions';
import Loading from '../../../../../../components/Loading';
import AdvancedEdit from "./containers/house_details/PropertyDetails/levels/Advanced";
import {useAuth} from "../../../../../../services/authServices.js";

const pages = [
  {
    label: 'main_page.my_ads.create_ad.house_details.label',
    icon: houseDetailsIcon,
    pages: [
      {
        label: 'main_page.my_ads.create_ad.house_details.property_details.label',
        path: 'property_details',
        level: '1.1.1',
        percentage: 15,
        component: PropertyDetails
      },
      {
        label: 'main_page.my_ads.create_ad.house_details.landlord_rules.label',
        path: 'lanlord_rules',
        level: '1.2.1',
        percentage: 30,
        component: LandlordRules
      },
      {
        label: 'main_page.my_ads.create_ad.house_details.property_areas.label',
        path: 'property_areas',
        level: '1.3.1',
        percentage: 45,
        component: PropertyAreas
      },
      {
        label: 'main_page.my_ads.create_ad.house_details.photographs.label',
        path: 'photographs',
        level: '1.4',
        percentage: 60,
        component: Photographs
      },
      {
        label: 'main_page.my_ads.create_ad.house_details.common_areas_details.label',
        path: 'common_areas_details',
        level: '1.5',
        percentage: 70,
        component: CommonAreasDetails
      }
    ]
  },
  {
    label: 'main_page.my_ads.create_ad.room_details.label',
    icon: roomDetailsIcon,
    pages: [
      {
        label: 'main_page.my_ads.create_ad.room_details.room_photos.label',
        path: 'room_photos',
        level: '2.1',
        percentage: 80,
        component: RoomPhotos
      },
      {
        label: 'main_page.my_ads.create_ad.room_details.room_details.label',
        path: 'room_details',
        level: '2.2',
        percentage: 87,
        component: RoomsMain
      },
      {
        label: 'main_page.my_ads.create_ad.room_details.room_amenities.label',
        path: 'room_amenities',
        level: '2.3',
        percentage: 90,
        component: RoomsMain
      },
      {
        label: 'main_page.my_ads.create_ad.room_details.room_prices.label',
        path: 'room_prices',
        level: '2.4',
        percentage: 95,
        component: RoomsMain
      },
    ]
  },
  {
    label: 'main_page.my_ads.create_ad.publish.label',
    icon: publishIcon,
    pages: [
      {
        label: 'main_page.my_ads.create_ad.publish.resume.label',
        path: 'resume',
        level: '3.1.1',
        percentage: 99,
        component: Resume
      }
    ]
  },
  {
    label: 'main_page.my_ads.create_ad.house_details.advanced_edition.label',
    pages: [
      {
        label: 'main_page.my_ads.create_ad.house_details.advanced_edition.label',
        level: 'advanced',
        percentage: 0,
        component: AdvancedEdit
      }
    ]
  },
];

const getPropertyNotFiledLevel = (property) => {
  const rooms = property.rooms;
  let propertyLevel;
  let propertyLevelArray = [];

  for (let room in rooms) {
    const roomObject = rooms[room];
    propertyLevelArray.push(roomObject.roomLevel)
  }

  if (propertyLevelArray.indexOf(undefined) > 0) {
    propertyLevel = {level: '2.1', room: propertyLevelArray.indexOf(undefined) + 1}
  } else if (propertyLevelArray.indexOf('2.1') > 0) {
    propertyLevel = {level: '2.2', room: propertyLevelArray.indexOf('2.1') + 1}
  } else if (propertyLevelArray.indexOf('2.2') > 0) {
    propertyLevel = {level: '2.3', room: propertyLevelArray.indexOf('2.2') + 1}
  } else if (propertyLevelArray.indexOf('2.3') > 0) {
    //check if the property is of the type of the property is apartment
    //because the apartaments only have the level 2.4 on the first room
    if (propertyLevelArray.indexOf('2.3') === 1 && property.accommodation === 'apartment') return;
    propertyLevel = {level: '2.4', room: propertyLevelArray.indexOf('2.3') + 1}
  } else {
    return
  }

  return propertyLevel;
};

const Ad = (props) => {
  let history = useHistory();

  const {t, i18n} = useTranslation();
  const location = useLocation();
  const params = useParams();
  const auth = useAuth();

  let {property_id, property_level, room_id} = params;
  if(!property_id) property_id = props.property_id;


  const allPages = useMemo(() => {
    let pagesAll = [];
    pages.map(page => (
      pagesAll.push(...page.pages)
    ));
    return pagesAll;
  }, [pages]);

  const existLevel = (level) => {
    const existingSubLevels = ['1.1.2', '1.1.3', '1.2.2', '1.2.3', '1.2.4', '1.2.5', '1.3.2','3.1.3'];
    if (allPages.find(page => page.level === level)) return true;
    // verify if it is a sublevel
    else if (existingSubLevels.includes(level)) return true;
    else return false
  };
  const existsRoom = (property, roomIdx) => (roomIdx <= Object.keys(property.rooms).length + 1);

  const getCurrentPage = (level) => {
    return allPages.find((page) => (level.substr(0, 3) === page.level.substr(0, 3)));
  };

  const [add, setAdd] = useState({
    currentPageLevel: {level: '1.1.0'},
    currentPage: undefined,
    maxPageLevel: '1.1.0',
    property: {rooms: {}}
  });

  const handleAddFieldChange = (field, value) => {
    setAdd(prevState => ({...prevState, [field]: value}))
  };

  useEffect(() => {
    if (props.editLevel) {
      let currentPage = getCurrentPage(props.editLevel);
      setAdd(prevState => ({
        ...prevState,
        currentPageLevel: {level: props.editLevel, room: props.editRoom},
        currentPage: {...currentPage, level: props.editLevel}
      }))
    }
  }, [props.editLevel, props.editRoom]);

  if (!props.editLevel) {
    useEffect(() => {
      props.displayFooter(false);

      return () => {
        props.displayFooter(true);
      }
    }, []);
  }

  useEffect(() => {
    (async () => {
      //reset the history when the user creates a new ad
      if (location.pathname === '/my_ads/ad/create')
        props.addHistoryReset();
      if (property_id) {
        const propertyModified = await get(`/property/get/${property_id}`);
        let propertyLevel;

          //check if there is information to be filled
          const toBeFiledLevel = getPropertyNotFiledLevel(propertyModified);

          if (props.setProperty) props.setProperty(propertyModified);

          if (props.editLevel) {
            propertyLevel = props.editLevel;

            propertyModified.isEditing = true;

            setAdd((prevState) => ({
              ...prevState,
              property: propertyModified
            }));

            props.addHistoryPush(propertyLevel, props.propertyAdHistory);

            return;
          }
          //if there is information left to be filled send the user the respective level
          else if (toBeFiledLevel && propertyModified.finished) {
            propertyLevel = toBeFiledLevel.level ? toBeFiledLevel : {level: toBeFiledLevel};
          }
          //if user refresh the page it will go back at where he was
          else if (props.propertyAdHistory.history.length > 0) {
            propertyLevel = props.propertyAdHistory.history.pop();
            if(typeof propertyLevel === "string") propertyLevel = {level: propertyLevel};
            //if the room_id and the property_level is passed in the url we need to verify
            // if there is a room with that id and if the level exists
          } else if (propertyModified.finished
                   && existsRoom(propertyModified, room_id)
                   && existLevel(property_level)) {
            propertyLevel = {level: property_level, room: room_id}
          } else
            propertyLevel = propertyModified.finished ? {level: '1.1.1'} : {level: propertyModified.level};

          setAdd({
            currentPageLevel: propertyLevel,
            currentPage: getCurrentPage(propertyLevel.level),
            maxPageLevel: toBeFiledLevel ? toBeFiledLevel.level : propertyModified.level,
            property: propertyModified
          });

          props.addHistoryPush(propertyLevel, props.propertyAdHistory)
      }
    })();

  }, [property_id]);

  const updateProperty = async (fields, level, roomId, setLoadingPercentage) => {
    if(auth.internalLoading) return false;
    auth.setInternalLoading(true);
    let propertyModified;
    let saveAndExit;
    if (fields && fields.saveAndExit) {
      saveAndExit = true;
      delete fields.saveAndExit;
    }

    //create new property of the type
    if (!property_id && !add.property?.id && fields?.accommodation) {
      propertyModified = await post(`/property/create`, fields);
      history.push(`/my_ads/ad/edit/${propertyModified.id}`);
      handleAddFieldChange('maxPageLevel', '1.1.1');
      auth.setInternalLoading(false);
    } else if (fields && add.property?.id) {
      let editedProperty;

      if (level === '1.4') editedProperty = await propertyServices.uploadPropertyPhotos(fields, add.property.id, setLoadingPercentage);

        // if the section is the last one the backend will send the next level if not
      // return and don't change the current level
      else if (level === '1.5') {
        editedProperty = await propertyServices.sectionDetails({...fields, currentLevel: level}, add.property.id);
        if (!editedProperty.nextLevel) {
          if(props.editLevel) editedProperty.property.isEditing = true;
          handleAddFieldChange('property', editedProperty.property);
          window.scrollTo(0, 0);
          if (saveAndExit) history.push('/my_ads/in_progress');
          auth.setInternalLoading(false);
          return;
        }
      } else if (level.startsWith('2.')) {
        if (level === '2.1') editedProperty = await propertyServices.uploadRoomPhotos(fields, add.property.id, setLoadingPercentage);
        else {
          editedProperty = await propertyServices.uploadRoomDetails({
            ...fields,
            currentLevel: level
          }, add.property.id, 'room_' + roomId);

          if (!editedProperty.nextLevel) {
            if(props.editLevel) editedProperty.property.isEditing = true;
            handleAddFieldChange('property', editedProperty.property);
            window.scrollTo(0, 0);
            if (saveAndExit) history.push('/my_ads/in_progress');
            auth.setInternalLoading(false);
            return;
          }
        }
      } else editedProperty = await propertyServices.editProperty({...fields, currentLevel: level}, add.property.id);
      propertyModified = {
        nextLevel: typeof editedProperty.nextLevel === 'string' ? editedProperty.nextLevel : editedProperty.nextLevel.toString(),
        maxLevel: editedProperty.maxLevel,
        ...editedProperty.property
      };
    } else {
      propertyModified = {level: '1.1.0'};
    }

    let nextLevel = {level: propertyModified.nextLevel ? propertyModified.nextLevel : propertyModified.level};

    //If there is room information to be filled the user is redirected to the respective level
    let noFiledLevel = getPropertyNotFiledLevel(propertyModified);
    if (propertyModified.finished && noFiledLevel) {
      nextLevel = getPropertyNotFiledLevel(propertyModified);
      //If the property is finished and the user tries to go to the level above '3.1.1' it will redirect it to
    //the adds screen
    } else if (propertyModified.finished && nextLevel.level === '3.1.2') {
      auth.setInternalLoading(false);
      return history.push('/my_ads');
    } else if (saveAndExit) {
      auth.setInternalLoading(false);
      return history.push('/my_ads/in_progress');
    }

    if (level) {
      props.addHistoryPush(nextLevel, props.propertyAdHistory)
    }

    if (props.editLevel) {
      propertyModified.isEditing = true;
      if (props.setProperty) props.setProperty(propertyModified);
      setAdd({
        currentPageLevel: add.currentPageLevel,
        currentPage: add.currentPage,
        maxPageLevel: add.maxPageLevel,
        property: propertyModified
      });
      auth.setInternalLoading(false);
      return true;
    }

    setAdd({
      currentPageLevel: nextLevel,
      currentPage: getCurrentPage(nextLevel.level),
      maxPageLevel: noFiledLevel ? noFiledLevel.level : propertyModified.level,
      property: propertyModified
    });

    window.scrollTo(0, 0);
    auth.setInternalLoading(false);
  };

  const changePage = (level, room = 1) => {
    handleAddFieldChange('currentPageLevel', {level, room});
    handleAddFieldChange('currentPage', getCurrentPage(level));
    props.addHistoryPush({level, room}, props.propertyAdHistory)
  };

  if (!property_id && !add.currentPageLevel['level'] || !add.currentPage && location.pathname === '/my_ads/ad/create') {
    return <PropertyType updateProperty={updateProperty} setCurrentPageLevel={level => handleAddFieldChange('currentPageLevel', {level})}/>;
  } else if ((property_id && !add.currentPage) || !add.property || (Object.keys(add.property).length === 1 && !add.property.level)) {
    return <Loading/>;
  } else {
    return (
      <>
        <div
          className={`Ad${props.simple ? ' simple' : ''}${props.simple && props.editLevel.startsWith('2') ? ' editRoom' : ''}`}>
          {!props.simple && <div className={'side-container'}>
            <div className={`sidebar`}>
              <div className={'links basic_container'}>
                {pages.map((p, index1) => (
                    (t(p.label) !== "advanced" && t(p.label) !== "avançado") &&
                  <div key={index1}>
                    <div className={'links__main_label'}>
                      <img src={p.icon}/>
                      {<p>{t(p.label)}</p>}
                    </div>
                    {p.pages.map((page, index2) => (
                      <button key={index2}
                              className={(add.currentPageLevel.level.substr(0, 3) === page.level.substr(0, 3)) ? 'active' : null}
                              disabled={parseFloat(page.level.substr(0, 3)) > parseFloat(add.maxPageLevel.substr(0, 3))}
                              onClick={() => {
                                changePage(page.level)
                              }}>
                        {index2 + 1 + '.'} {t(page.label)}
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>}
          <div className={'inner-container'}>
            <div className={'content'}>
              <add.currentPage.component key={add.currentPageLevel.level} t={t} updateProperty={updateProperty}
                                         currentPageLevel={add.currentPageLevel.level}
                                         currentRoomNumber={add.currentPageLevel.room}
                                         property={add.property} changePage={changePage}
                                          goToNextAfterUpdate={!props.simple}/>
            </div>
            {!props.simple && <div className={`progress_circle`}>
              <CircleProgress primaryColor={['#DEE9EF', '#18A0FB']} fontSize={'0.7rem'} fontColor={'black'}
                              secondaryColor={'#F5F7FA'} strokeWidth={5}
                              percentage={add.currentPageLevel.level === '3.1.2' || add.currentPageLevel.level === '3.1.3'
                                ? 100
                                : add.currentPage.percentage}
                              width={60}/>
            </div>}
          </div>
        </div>
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    propertyAdHistory: state.propertyAdHistory
  };
}

const actionCreator = {
  addHistoryPush: propertyAdHistoryActions.push,
  addHistoryPop: propertyAdHistoryActions.pop,
  addHistoryReset: propertyAdHistoryActions.reset,
  displayFooter: displayFooterActions.display
};

export default connect(mapStateToProps, actionCreator)(Ad);

import React, { useEffect, useState, useMemo } from "react";
import "./style.less";

import { useTranslation } from "react-i18next";
import MyAccountNav from "../MyAccountNav";
import { dashboardServices } from "../../../../../../services";
import DatePicker from "react-datepicker";
import Loading from "../../../../../../components/Loading";

import CalendarIcon from "../../../../../../components/svg/Calendar";
import PaperClipIcon from "../../../../../../components/svg/PaperClip";
import DownloadIcon from "../../../../../../components/svg/Download";
import Pagination from "../../../../../../components/Pagination";

const BillingHistory = (props) => {
    const { t, i18n } = useTranslation();
    const PAGE_SIZE = 10;

    const [invoices, setInvoices] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const updateInvoices = async () => {
        setLoading(true);
        const { invoices, total } = await dashboardServices.getInvoiceHistory({
            ...filters,
            page: currentPage - 1,
            pageSize: PAGE_SIZE
        });
        setTotal(total);
        setInvoices(invoices);
        setLoading(false);
    }

    useEffect( () => {
        (async () => {
            await updateInvoices();
        })();
    }, [filters, currentPage]);


    return (
        <>
            <div className={"billing-history__container"}>
                <MyAccountNav pages={props.pages}/>
                <div className="invoices">
                    <div className="invoices__content">
                        <div className="invoices__header">
                            <h5 className="invoices__header__title">
                                {t("invoices.title")}
                            </h5>
                            <div className="invoices__header__actions">
                                <div className="invoices__header__actions__item">
                                    <label htmlFor="invoicesFrom">{t("invoices.from")}</label>
                                    <div className="invoices__header__actions__item__input">
                                        <DatePicker className="datepicker__input"
                                            id={"invoicesFrom"}
                                            name={"invoicesFrom"}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat="MM/yyyy"
                                            selected={filters?.fromDate}
                                            isClearable={true}
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                            onChange={(date) => {
                                                setFilters({ ...filters, fromDate: date })
                                            }} />
                                        <div className="datepicker__input__icon-wrapper">
                                            <CalendarIcon />
                                        </div>
                                    </div>
                                </div>

                                <div className="invoices__header__actions__item">
                                    <label htmlFor="invoicesTo">{t("invoices.to")}</label>
                                    <div className="invoices__header__actions__item__input">
                                        <DatePicker className="datepicker__input"
                                            id={"invoicesUntil"}
                                            name={"invoicesUntil"}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat="MM/yyyy"
                                            selected={filters?.untilDate}
                                            isClearable={true}
                                            showMonthYearPicker
                                            minDate={filters?.fromDate}
                                            showFullMonthYearPicker
                                            onChange={(date) => {
                                                setFilters({ ...filters, untilDate: date })
                                            }} />
                                        <div className="datepicker__input__icon-wrapper">
                                            <CalendarIcon />
                                        </div>
                                    </div>
                                </div>

                                <div className="btn btn-tertiary"
                                    onClick={() => {
                                        setFilters({});
                                    }}>
                                    {t("invoices.clear_filters")}
                                </div>
                            </div>
                        </div>

                        <div className="invoices__list">
                            {loading && <Loading />}
                            {!loading && invoices.length === 0 && <div className={"no-invoices"}>No data to show</div>}
                            {invoices.map((invoice) => {
                                return (
                                    <div className="invoices__list__container" key={invoice.id}>
                                        <div className="invoices__list__container__item">
                                            <PaperClipIcon />

                                            <div className="invoices__list__container__item__content">
                                                <strong>{invoice.tenant.name}</strong>
                                                {invoice.id.split("-")?.[0]}
                                            </div>
                                            <div className="invoices__list__container__item__content">
                                                <strong>{t("invoices.invoice")}</strong>
                                                {`${new Date(invoice.date).getDate()} 
                                                    ${new Date(invoice.date).toLocaleString(i18n.language, { month: 'long' }) + " " +
                                                    new Date(invoice.date).getFullYear()}
                                                `}
                                            </div>

                                        </div>
                                        <button className="btn btn-primary" onClick={() => {
                                            window.open(invoice.permalink, "_blank")
                                        }}>
                                            <DownloadIcon />
                                            {t("buttons.download")}
                                        </button>
                                        {/* <button className="btn btn-secondary">
                                        {t("buttons.print")}
                                        <img src={print} alt={"icon"} />
                                    </button> */}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <Pagination currentPage={currentPage}
                        totalCount={total}
                        pageSize={PAGE_SIZE}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </>
    );
};

export default BillingHistory;
